import { Box } from "@mantine/core";
import Typography from "shared/ui/Typography/Typography";

interface Props {
    title: string;
    subTitle: string;
}
function CountPromo({ title, subTitle }: Props): JSX.Element {
    return (
        <Box
            maw={300}
            pb={20}
            sx={(theme) => ({
                borderBottom: `2px solid ${theme.colors.main_Black[0]}`,
            })}
            component="section">
            <Typography tag="display">{title}</Typography>
            <Typography tag="osSemibold" mt={10}>
                {subTitle}
            </Typography>
        </Box>
    );
}
export default CountPromo;
