import { Box, Flex } from "@mantine/core";
import CountPromo from "entities/index/ui/CountPromo";
import { IconArrowUpRight } from "public/icons/IconArrowUpRight";
import { IconClockEight } from "public/icons/IconClockEight";
import useCategories from "shared/api/categories/hooks/queries/useCategories";
import { CategoryId } from "shared/api/categories/types";
import ButtonRound from "shared/ui/Button/ButtonRound";
import Typography from "shared/ui/Typography/Typography";
import { Color } from "styles/index";

function CountPromoList(): JSX.Element {
    const { data } = useCategories();
    const metalPenCategorySlug = data?.data.find((x) => x.id === CategoryId.METAL_PEN)?.slug;
    return (
        <Flex gap={50} direction="row" justify={{ md: "space-between" }} wrap="wrap">
            <Box maw={300}>
                <IconClockEight size={30} color={Color.main_Black} />
                <Typography tag="buttonCaps" mt={10}>
                    Cувениры с логотипом за 24 часа с момента оплаты и утверждения макета
                </Typography>
                {metalPenCategorySlug && (
                    <ButtonRound
                        label="Подробнее"
                        rightIcon={<IconArrowUpRight />}
                        mt={20}
                        href={{
                            pathname: "/catalog/[categoryId]",
                            query: { categoryId: metalPenCategorySlug },
                        }}
                    />
                )}
            </Box>
            <CountPromo title="3 млн" subTitle="Ручек на складе в России" />
            <CountPromo title="от 9.99₽" subTitle="Пластиковые ручки из ABC пластика под нанесение логотипа" />
        </Flex>
    );
}
export default CountPromoList;
