import { Box, Flex } from "@mantine/core";
import HomePageMainPicture from "public/images/home-page-main.webp";
import { Picture } from "shared/ui/Picture";
import AdvantagePromoList from "widgets/index/AdvantagePromoList";
import AppDescriptionPromo from "widgets/index/AppDescriptionPromo";
import CountPromoList from "widgets/index/CountPromoList/CountPromoList";

export default function MainIndex() {
    return (
        <Box mt={65} mb={90} component="article">
            <Flex gap={50} align="flex-start" wrap="wrap">
                <Flex maw={600} sx={{ flex: 1 }}>
                    <AppDescriptionPromo />
                </Flex>
                <Flex
                    h={{ base: 368, md: 573 }}
                    w={{ base: "100%", sm: 334, md: 500 }}
                    maw={500}
                    pos="relative"
                    justify="flex-start">
                    <Picture
                        priority
                        src={HomePageMainPicture}
                        alt="пример продукции: флешка, ручка и внешний HDD"
                        fill
                    />
                </Flex>
                <Box miw={200} sx={{ flex: 1 }}>
                    <CountPromoList />
                </Box>
            </Flex>
            <Box mt={40}>
                <AdvantagePromoList />
            </Box>
        </Box>
    );
}
