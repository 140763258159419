import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    root: {
        display: "grid",
        gap: 60,
        gridTemplateColumns: "repeat(3, 1fr)",
        [`@media (max-width: ${theme.breakpoints.md - 2}px)`]: {
            gridTemplateColumns: "1fr",
        },
    },
}));
