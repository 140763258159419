import { createStyles } from "@mantine/core";

// TODO: перенести в глобальную тему?
export const useStyles = createStyles((theme) => ({
    title: {
        [`@media (max-width: ${theme.breakpoints.md - 2}px)`]: {
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "48px",
        },
    },
}));
