import { Box } from "@mantine/core";
import AdvantagePromo from "entities/index/ui/AdvantagePromo";
import { StaticImageData } from "next/image";
import { Picture } from "shared/ui/Picture";
import PenAndRuler from "widgets/index/AdvantagePromoList/pictures/penAndRuler.png";
import MachineTools from "./pictures/machineTools.png";
import Storage from "./pictures/storage.png";
import { useStyles } from "./styles";

interface ItemData {
    title: string;
    description: string;
    image: StaticImageData;
    alt: string;
}
const listMock: ItemData[] = [
    {
        image: PenAndRuler,
        alt: "Ручка и линейка",
        title: "Производство ручек по пантону более 1 000 000 шт. всего за 1,5 месяца",
        description:
            "Производство ручек по индивидуальному пантону заказчика с нанесением логотипа и доставкой в Россию всего за 1,5 месяца с момента оплаты.",
    },
    {
        image: Storage,
        alt: "Склад",
        title: "Более 3 000 000 ручек на складе в России!",
        description:
            "Всегда в наличии на складе в России более 3 000 000 ручек, а также ежемесячные поставки ручек с одного из крупнейших складов Европы.",
    },
    {
        image: MachineTools,
        alt: "Станки",
        title: "Собственное оборудование",
        description:
            "Используется собственное современное оборудование по персонализации: американские гравёры фирмы, японские УФ-машины, Корейские – Тампостанки, являющиеся самым профессиональным оборудованием на рынке.",
    },
];

function AdvantagePromoList(): JSX.Element {
    const list: ItemData[] = listMock;
    const { classes } = useStyles();
    return (
        <Box className={classes.root}>
            {list.map((item, index) => (
                <AdvantagePromo
                    key={index}
                    image={<Picture src={item.image} alt={item.alt} />}
                    title={item.title}
                    description={item.description}
                />
            ))}
        </Box>
    );
}
export default AdvantagePromoList;
